/* eslint-disable @typescript-eslint/no-explicit-any */
import { IUserConnection } from 'interfaces/redux/IUserConnection';
import { handleFaGrantNotification } from 'actions/action-socket';
import { useCallback, useEffect } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';
import { Socket, io } from 'socket.io-client';
import { debounce } from 'lodash';
import { fetchHiddenQuestions, refreshPatientPhones } from 'actions/action-patient';
import { notifySuccess } from 'actions/action-notifications';
import { updateTaskByIdsFCSelfService } from 'actions/action-tasks';
import { IPayloadFCSocket } from '../interfaces/redux/IPayloadFCSocket';
import {
  EventListenersSocket as listeners,
  EventEmitsSocket as emits,
} from '../interfaces/redux/ISocket';
import { useAppDispatch } from './useAppDispatch';
import { IFaGrantNotification } from '../interfaces/redux/IFaGrantNotification';
import { useTypedSelector } from './use-typed-selector';

interface IUseSocket {
  socketReducer: {
    socket: Socket | null;
    userConnections: object;
  };
  selectedPatientId: number;
}
const BASE_URL_SOCKET = 'wss://socketio.uat-arbor.trellisrx.com';
const defaultConfig = { transports: ['websocket'], withCredentials: true };
let multiUserSocket: any = null;

const connected = 'connect';
export const useMultiUserSocket = (userId: number) => {
  const selectedPatientId = useSelector((state: RootStateOrAny) => state.selectedPatientId);
  const customerId = useSelector((state: RootStateOrAny) => state.filters.selectedCustomerId);

  const sendConnections = (conns: any) => {
    const filteredConns = conns
      .filter(
        (conn: any) =>
          conn.room === customerId && conn.id !== userId && conn.patientId === selectedPatientId,
      )
      .reduce((acc: any, item: any) => ({ ...acc, [item.id]: item.name }), {});
    const message = { source: 'multiuserConnections', conns: filteredConns };
    window.postMessage(message);
  };

  if (multiUserSocket instanceof Socket) {
    if (!multiUserSocket.connected) multiUserSocket.connect();

    if (!multiUserSocket.hasListeners(connected)) {
      multiUserSocket.on(connected, () => {
        multiUserSocket.emit(emits.roomAccessesCustomer, customerId);
        multiUserSocket.emit(emits.patientAccesses, selectedPatientId);
      });
    }

    if (!multiUserSocket.hasListeners(listeners.allOnlineClients)) {
      multiUserSocket.on(listeners.allOnlineClients, (userCons: IUserConnection[]) => {
        sendConnections(userCons);
      });
    }
  } else {
    multiUserSocket = io(BASE_URL_SOCKET, defaultConfig);
  }

  useEffect(() => {
    if (multiUserSocket instanceof Socket) {
      multiUserSocket.emit(emits.roomAccessesCustomer, customerId);
    }
  }, [customerId]);

  useEffect(() => {
    if (multiUserSocket instanceof Socket) {
      if (selectedPatientId < 1) return;

      if (!multiUserSocket.connected) multiUserSocket.connect();

      if (multiUserSocket.hasListeners(listeners.allOnlineClients)) {
        multiUserSocket.removeListener(listeners.allOnlineClients);

        multiUserSocket.on(listeners.allOnlineClients, (userCons: IUserConnection[]) => {
          sendConnections(userCons);
        });
      }

      multiUserSocket.emit(emits.patientAccesses, selectedPatientId);
    }
  }, [selectedPatientId]);

  return {};
};

export const useRulesEngineSocket = () => {
  const dispatch = useAppDispatch();
  const rulesEngineSocket = useSelector(
    (state: RootStateOrAny) => state.socketReducer.rulesEngineSocket,
  );
  const selectedPatientId = useSelector((state: RootStateOrAny) => state.selectedPatientId);
  const debouncePatientHiddenQuestionHandler = debounce(() => {
    dispatch(fetchHiddenQuestions());
  }, 1000);

  if (rulesEngineSocket instanceof Socket) {
    if (!rulesEngineSocket.connected) rulesEngineSocket.connect();

    if (!rulesEngineSocket.hasListeners(listeners.questionShow)) {
      rulesEngineSocket.on(listeners.questionShow, (notification: { patient_id: number }) => {
        if (notification.patient_id === selectedPatientId) {
          debouncePatientHiddenQuestionHandler();
        }
      });
    }

    if (!rulesEngineSocket.hasListeners(listeners.questionHide)) {
      rulesEngineSocket.on(listeners.questionHide, (notification: { patient_id: number }) => {
        if (notification.patient_id === selectedPatientId) {
          debouncePatientHiddenQuestionHandler();
        }
      });
    }
  }

  return {};
};

export const useFaSocket = () => {
  const dispatch = useAppDispatch();
  const faSocket = useSelector((state: RootStateOrAny) => state.socketReducer.faSocket);
  const customerId = useTypedSelector(state => state.filters.selectedCustomerId);
  const debounceHandleFaGrantNotification = debounce(notification => {
    dispatch(
      handleFaGrantNotification({
        socket: faSocket,
        notification,
        forceTaskType: 'FA',
      }),
    );
  }, 10000);

  if (faSocket instanceof Socket) {
    if (!faSocket.connected) faSocket.connect();

    if (!faSocket.hasListeners(listeners.notifyFaGrant)) {
      faSocket.on(listeners.notifyFaGrant, (notification: IFaGrantNotification) => {
        if (notification.customer_id === customerId) {
          debounceHandleFaGrantNotification(notification);
        }
      });
    }
  }

  return {};
};

export const useFCSocket = () => {
  const dispatch = useAppDispatch();
  const fcSocket = useSelector((state: RootStateOrAny) => state.socketReducer.fcSocket);
  const selectedPatientId = useSelector((state: RootStateOrAny) => state.selectedPatientId);
  const customerId = useSelector((state: RootStateOrAny) => state.filters.selectedCustomerId);

  const refreshPatientPhonesHandler = useCallback(
    (payload: IPayloadFCSocket) => {
      if (payload.patientId === selectedPatientId) {
        dispatch(refreshPatientPhones(selectedPatientId));
        dispatch(notifySuccess(`Patient ${payload.name} replied to the sms`));
      }
    },
    [selectedPatientId],
  );

  const refreshUpdateFCSS = useCallback(
    (payload: IPayloadFCSocket) => {
      if (payload.patientId === selectedPatientId && payload?.taskIds?.length) {
        dispatch(updateTaskByIdsFCSelfService(payload.taskIds));
        dispatch(
          notifySuccess(`Patient ${payload.name} responded to FC Self-Service questionnaire`),
        );
      }
    },
    [selectedPatientId],
  );

  if (fcSocket instanceof Socket) {
    if (!fcSocket.connected) fcSocket.connect();

    if (!fcSocket.hasListeners(connected)) {
      fcSocket.on(connected, () => {
        fcSocket.emit(emits.roomAccessesCustomer, customerId);
      });
    }

    if (!fcSocket.hasListeners(listeners.updatePhones)) {
      fcSocket.on(listeners.updatePhones, refreshPatientPhonesHandler);
    }

    if (!fcSocket.hasListeners(listeners.updateStatusFCSS)) {
      fcSocket.on(listeners.updateStatusFCSS, refreshUpdateFCSS);
    }
  }

  useEffect(() => {
    if (fcSocket instanceof Socket) {
      fcSocket.emit(emits.roomAccessesCustomer, customerId);
    }
  }, [customerId]);

  useEffect(() => {
    if (fcSocket instanceof Socket) {
      if (selectedPatientId < 1) return;

      if (!fcSocket.connected) fcSocket.connect();

      if (fcSocket.hasListeners(listeners.updatePhones)) {
        fcSocket.removeListener(listeners.updatePhones);
        fcSocket.on(listeners.updatePhones, refreshPatientPhonesHandler);
      }

      if (fcSocket.hasListeners(listeners.updateStatusFCSS)) {
        fcSocket.removeListener(listeners.updateStatusFCSS);
        fcSocket.on(listeners.updateStatusFCSS, refreshUpdateFCSS);
      }
    }
  }, [selectedPatientId]);

  return {};
};

export const useSocket = (userId: number) => {
  useMultiUserSocket(userId);
  return {};
};
